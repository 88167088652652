<template>
    <div class="password-reset-finish-view ViewWrapper">
        <clv-head-meta :title="$t('Password Reset')"></clv-head-meta>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <common-box-logo></common-box-logo>

                    <!-- BODY -->
                    <div class="gk-card gk-card--core-style-1 mb-5">

                        <!-- LOADER 2020-02-04 -->
                        <element-loading :active="loading" :background-color="'rgba(255, 255, 255, 0.9)'"></element-loading>

                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Password Reset') }}</p>
                            <p class="mb-0 text-center weight-3 size-5">{{ $t('Set a new password') }}</p>
                        </div>

                        <!-- RESET ERROR -->
                        <div class="alert alert-danger C-Alert" role="alert" v-if="error">
                            <p class="mb-0">{{ $t('Password Reset Failed' ) }}</p>
                            <p class="mb-0 small">{{ $t('Probably the reset key has expired' ) }}</p>
                        </div>

                        <!-- SUCCESS -->
                        <div class="alert alert-success C-Alert text-center" role="alert" v-if="passwordResetSuccess">
                            <p class="mb-0">{{ $t('Password Changed' ) }}</p>
                        </div>

                        <!-- THE FORM -->
                        <form class="cltlr-form-label-style-1" v-on:submit.prevent="submit" data-vv-scope="passwordResetFinishForm" v-if="!passwordResetSuccess">
                            <div class="form-group">
                                <label for="password">{{ $t('Password') }}</label>
                                <input type="password" class="form-control" id="password" name="vv_password" ref="vv_password" v-validate="'required|min:8|max:64|password_strength'" v-model="formFields.password">
                                <span class="small text-danger" v-show="errors.has('passwordResetFinishForm.vv_password')">{{ $t('Password_Validation_1') }}</span>
                            </div>
                            <div class="form-group">
                                <label for="passwordRepeat">{{ $t('Repeat Password') }}</label>
                                <input type="password" class="form-control" id="passwordRepeat" name="vv_passwordRepeat" v-validate="'required|confirmed:vv_password'" v-model="formFields.passwordRepeat" data-vv-as="vv_passwordRepeat">
                                <span class="small text-danger" v-show="errors.has('passwordResetFinishForm.vv_passwordRepeat')">{{ $t('Password_Validation_No_Match_1') }}</span>
                            </div>
                            <div class="form-group mt-4">
                                <button type="button" class="btn btn-primary" @click="submit">{{ $t('Set new password') }}</button>
                            </div>
                        </form>

                        <common-box-links></common-box-links>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AccountService } from '../common/services/api.service';
import CommonValidators from '../common/utils/commonValidators.utils';

export default {
    name: 'PasswordResetInitView',
    data () {
        return {
            // Persistence / Async //////////
            loading: false,
            error: null,
            passwordResetSuccess: false,

            // Form //////////
            formFields: {
                key: '',
                password: '',
                passwordRepeat: ''
            }
        };
    },
    mounted () {
        // Init key.
        let key = '';

        // Get key from url params.
        try {
            key = this.$route.query.key;
        } catch (e) {
            key = null;
        }

        // Validate and clean activation key.
        if (!CommonValidators.validateString(key)) {
            key = '';
        }

        // Set key to formFields.
        this.formFields.key = key;
    },
    methods: {
        submit () {
            // Validate Form.
            this.$validator.validateAll('passwordResetFinishForm').then(valid => {
                // If form is valid, proceed.
                if (valid) {
                    // Start loader.
                    this.loading = true;

                    // Reset error.
                    this.error = null;

                    // Reset flag.
                    this.passwordResetSuccess = false;

                    // Prepare DTO.
                    const dto = {
                        key: this.formFields.key,
                        newPassword: this.formFields.password
                    };

                    // Make request to Zeus to sign up new account.
                    AccountService.finishPasswordReset(dto).then(({ data }) => {
                        // Set flag to true.
                        // This will force a box to be displayed (with success state).
                        this.passwordResetSuccess = true;
                    }).catch((reason) => {
                        // Set error.
                        this.error = reason;
                    }).finally(() => {
                        // Stop loader.
                        this.loading = false;
                    });
                }
            });
        }
    }
};
</script>
